import { FC, Suspense } from "react";
import { GameCode, OriginalGames, CasinoGameCategory } from "src/core/casino/casino.model";
import PageContainer from "src/containers/PageContainer/PageContainer";
import DeprecatedStatsTable from "src/components/stats-table/DeprecatedStatsTable";
import GameCodeContext from "src/containers/Games/GameCodeContext";
import { useGamesLocation } from "src/containers/Games/hooks/useGamesLocation";
import { useMaintenance } from "src/core/maintenance/hooks/useMaintenance";
import { useGameSelectedAssetCheck } from "src/containers/Games/hooks/useGameSelectedAssetCheck";
import Maintenance from "src/static/Maintenance";
import {
    getSeoContentForGame,
} from "src/containers/Games/constants/seoContent";
import classNames from "classnames/bind";
import styles from "src/containers/Games/styles.scss";
import { games } from "src/games/config";
import { Navigate } from "react-router-dom";
import { Path } from "src/core/paths/paths";
import { useMuteOutsidePage } from "src/containers/Games/hooks/useMuteOutsidePage";

const cx = classNames.bind(styles);

const getShouldShowStatsTable = (gameCode: GameCode) => ![OriginalGames.CfBattle, CasinoGameCategory.Slots].includes(gameCode)

const GamePage: FC = () => {
    const { gameCode } = useGamesLocation();
    const isMaintenance = useMaintenance({ forGame: gameCode });

    useGameSelectedAssetCheck();
    useMuteOutsidePage();

    if (isMaintenance) {
        return <Maintenance />;
    }

    const seoTitleAndDescription = getSeoContentForGame(gameCode!)
    const Component = games[gameCode ?? ""]?.component

    if (!Component) {
        return <Navigate to={Path.Games} />;
    }

    return (
        <GameCodeContext.Provider value={gameCode as GameCode}>
            <PageContainer
                showFooter
                showHeader
                dark
                pageTitle={seoTitleAndDescription.title} pageDescription={seoTitleAndDescription.description}
                contentClass={cx("game-page")}
            >
                <Suspense
                    fallback={
                        <div className={cx("game-loader")}>
                            <span className={cx("bold", "large")}>Loading game...</span>
                        </div>
                    }
                >
                    <Component />
                </Suspense>
                {getShouldShowStatsTable(gameCode as GameCode) ? <DeprecatedStatsTable /> : null}
            </PageContainer>
        </GameCodeContext.Provider>
    );
};

export default GamePage;